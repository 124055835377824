import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoginState } from '../classes/login-state';

export const selectLoginState = createFeatureSelector<LoginState>('login');

export const selectLoginSettings = createSelector(
    selectLoginState,
    state => state.settings
);

export const selectLoginStateType = createSelector(
    selectLoginState,
    state => state.stateType
);

export const selectLoginResult = createSelector(
    selectLoginState,
    state => state.result
);

export const selectLoginErrorResult = createSelector(
    selectLoginState,
    state => state.error
);

export const selectErrorVisibility = createSelector(
    selectLoginState,
    state => state.errorVisibility
);

export const selectEmailCheckResult = createSelector(
    selectLoginState,
    state => state.emailCheckResult
);

export const selectQueryParams = createSelector(
    selectLoginState,
    state => state.queryParams
);

export const selectFormReadyStatus = createSelector(
    selectLoginState,
    state => state.formIsReady
);

export const selectFormSubmittedStatus = createSelector(
    selectLoginState,
    state => state.formIsSubmitted
);
