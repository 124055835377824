import { LoggingService, LogLevel } from "../services/logging.service";
import { AppSettings } from "../classes/app-settings";

const loggingFactory = (appSettings: AppSettings, window: Window) => new LoggingService(LogLevel.All, appSettings.intutoIdentityServer, window);

export const loggingProvider = {
    provide: LoggingService,
    useFactory: loggingFactory,
    deps: [AppSettings, Window]
}
