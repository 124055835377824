import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LogoComponent } from "./logo/logo.component";
import { TermsComponent } from "./terms/terms.component";
import { ArraySortPipe } from "../pipes/array-sort.pipe";
import { SafeHtmlPipe } from "../pipes/safe-html.pipe";
import { CastAsPipe } from "../pipes/cast-as.pipe";
import { ErrorDialogModal } from "./error-dialog/error-dialog.modal";

@NgModule({
    declarations: [
        LogoComponent,
        TermsComponent,
        ArraySortPipe,
        SafeHtmlPipe,
        CastAsPipe,
        ErrorDialogModal
    ],
    imports: [CommonModule],
    exports: [
        LogoComponent,
        TermsComponent,
        ArraySortPipe,
        SafeHtmlPipe,
        CastAsPipe,
        ErrorDialogModal
    ]
})
export class SharedComponentModule { }