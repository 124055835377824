import { Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { ControlContainer, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { AppSettings } from "../../../../../shared/classes/app-settings";
import { LoginState } from "../../classes/login-state";
import { LoginStateType } from "../../classes/login-state-type";
import * as fromLogin from "../../store/login.actions";
import * as LoginSelectors from "../../store/login.selectors";

@Component({
    selector: "password-input",
    templateUrl: "./password-input.component.html",
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ]
})
export class PasswordInputComponent implements OnDestroy {
    @ViewChild("passwordInput") passwordInput: ElementRef;
    @Input() form: FormGroup;
    destroy$ = new Subject<boolean>();
    formIsSubmitted$ = this.store.select(LoginSelectors.selectFormSubmittedStatus);

    constructor(
        private store: Store<LoginState>,
        public appSettings: AppSettings
    ) {
        this.store.select(LoginSelectors.selectLoginStateType).pipe(
            takeUntil(this.destroy$),
            map(stateType => {
                if (stateType === LoginStateType.EnterPassword) {
                    this.store.dispatch(fromLogin.toggleErrorVisibility({ password: false }));
                    this.store.dispatch(fromLogin.markFormAsReady());
                    this.form.controls.password.setValidators(Validators.required);
                    this.form.controls.password.updateValueAndValidity();
                    setTimeout(() => this.passwordInput.nativeElement.focus());
                }
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    back() {
        this.store.dispatch(fromLogin.setState({ stateType: LoginStateType.EnterUsername }));
    }
}