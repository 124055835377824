export class HcaptchaVerifiedEvent extends Event {
    token: string | null;
    /**
     * Captcha Response key
     */
    eKey: string | null;

    constructor() {
        super("HCaptchaVerifiedEvent");
    }
}
