declare global {
    interface Window {
        intuto: any;
    }
}

window.intuto = window.intuto || {};

export enum LogLevel {
    All = "All",
    Trace = "Trace",
    Debug = "Debug",
    Info = "Info",
    Warn = "Warn",
    Error = "Error",
    Fatal = "Fatal",
    Silent = "Silent"
}

export class LoggingService {
    private readonly _simplyFunConfig: any;
    private readonly _logLevel: LogLevel;
    private _window: any;
    private readonly _logLevelOrdinals: Map<LogLevel, number>;
    private readonly _contextProperties: Map<string, string> | undefined;

    constructor(logLevel: LogLevel, simplyFunConfig: any, window: any, contextProperties: Map<string, string> | undefined = undefined) {
        if (!this.configIsValid(simplyFunConfig)) {
            throw "Error loggingService simplyFunConfig object is not valid.";
        }

        this._simplyFunConfig = simplyFunConfig;
        this._logLevel = logLevel;

        this._window = window;
        this._logLevelOrdinals = new Map<LogLevel, number>();
        this._contextProperties = contextProperties;
        const logLevels = Object.keys(LogLevel) as LogLevel[];

        logLevels.forEach((logLevel, ordinal) => {
            this._logLevelOrdinals.set(logLevel, ordinal);
        });
    }

    private getLogLevelOrdinal(logLevel: LogLevel): number {
        return this._logLevelOrdinals.get(logLevel);
    }

    private canLog(logLevel: LogLevel): boolean {
        return logLevel !== LogLevel.Silent && (logLevel === LogLevel.All || (
            this.getLogLevelOrdinal(logLevel) >= this.getLogLevelOrdinal(this._logLevel)
        ));
    }

    private configIsValid(simplyFunConfig): boolean {
        return (
            simplyFunConfig.jsLoggingEnabled !== undefined &&
            simplyFunConfig.jsLoggingSubscribeGlobalError !== undefined &&
            simplyFunConfig.jsLoggingSubscribeAngularJsError !== undefined &&
            simplyFunConfig.jsLoggingExcludeStringList !== undefined &&
            simplyFunConfig.jsLoggingLogHandledPromiseRejection !== undefined &&
            simplyFunConfig.jsLoggingLogUnhandledPromiseRejection !== undefined &&
            Array.isArray(simplyFunConfig.jsLoggingExcludeStringList)
        ) || (
                simplyFunConfig.JsLoggingEnabled !== undefined &&
                simplyFunConfig.JsLoggingSubscribeGlobalError !== undefined &&
                simplyFunConfig.JsLoggingSubscribeAngularJsError !== undefined &&
                simplyFunConfig.JsLoggingExcludeStringList !== undefined &&
                simplyFunConfig.JsLoggingLogHandledPromiseRejection !== undefined &&
                simplyFunConfig.JsLoggingLogUnhandledPromiseRejection !== undefined &&
                Array.isArray(simplyFunConfig.JsLoggingExcludeStringList)
            );
    }
    private isObject(value): boolean {
        return value !== null && typeof value === 'object';
    }
    private isErrorObject(message: string | any): boolean {
        return message instanceof Error || message.prototype instanceof Error;
    }

    private isErrorEventObject(message: string | any): boolean {
        return message instanceof ErrorEvent || message.prototype instanceof ErrorEvent;
    }

    private errorObjectSerializer(key, value): any {
        if (value !== null && (value instanceof Error || value.prototype instanceof Error)) {
            const error = {};

            Object.getOwnPropertyNames(value).forEach(function (propName) {
                error[propName] = value[propName];
            });

            return error;
        }

        return value;
    }

    private newLogEntry(logLevel: LogLevel, message: string | any): any {
        let jsonMessage = "";
        let obj = null;
        if (message !== null && (message instanceof ErrorEvent || message.prototype instanceof ErrorEvent)) {
            obj = message.error;
        }
        else if (this.isObject(message)) {
            obj = message;
        } else {
            obj = { message: message };
        }

        // Add context property key/values to our message object
        if (this._contextProperties !== undefined) {
            this._contextProperties.forEach((value, name) => {
                if (obj[name] === undefined) {
                    obj[name] = value;
                }
            });
        }

        jsonMessage = JSON.stringify(obj, this.errorObjectSerializer);

        return {
            Logged: (new Date()).toISOString(),
            LogLevel: logLevel,
            Message: jsonMessage,
            Path: window.location.pathname + window.location.search + window.location.hash
        };
    }

    /**
     * @function write
     * @memberof LoggingService     *
     * @param {LogLevel} logLevel
     * @param {string|object} message
     * @description Write a trace level log entry
     */
    write(logLevel: LogLevel, message: string | any) {
        if (this.canLog(logLevel) === false) {
            return;
        }
        if (this._simplyFunConfig.jsLoggingEnabled === false || this._simplyFunConfig.JsLoggingEnabled === false) {
            return;
        }

        try {
            // If we have an error that was logged be a promise (logged by the logAnyUnexpectedError handler)
            // then prevent rejection exclusion from skipping the error logging to backend
            const skipExcludeListCheck = this.isErrorObject(message) && message.message.startsWith("Promise");

            // later dev we may want to batch send
            let queue = [];
            const logEntry = this.newLogEntry(logLevel, message);
            const excludeList = this._simplyFunConfig.jsLoggingExcludeStringList !== undefined
                ? this._simplyFunConfig.jsLoggingExcludeStringList
                : this._simplyFunConfig.JsLoggingExcludeStringList;
            for (let i = 0; i < excludeList.length; i++) {
                // if find the string in the log message then ignore the log entry/message
                const stringToCheck = (excludeList[i]);
                if (skipExcludeListCheck === false && logEntry.Message.indexOf(stringToCheck) !== -1) {
                    return;
                }
            }

            queue.push(logEntry);

            let json = JSON.stringify(queue);

            if (logLevel === LogLevel.Trace) {
                console.info(queue[0]);
            }

            if (navigator.sendBeacon) {
                const url = "/api/logging/writebeacon";
                const blob = new Blob([json], { type: "text/plain; charset=UTF-8" });
                navigator.sendBeacon(url, blob);
            } else {
                const url = "/api/logging/write";
                const request = new XMLHttpRequest();
                request.onreadystatechange = function () {
                    const done = 4,
                        ok = 200;
                    if (request.readyState === done && request.status !== ok) {
                        if (request.responseText) {
                            console.warn("loggingService.write failed : " + request.status);
                        }
                    }
                };
                request.open("POST", url, true);
                request.setRequestHeader("Content-Type", "application/json");
                request.send(json);
            }
        } catch (err) {
            console.warn("loggingService.write failed : " + err.message);
        }
    }

    /**
     * @function writeTrace
     * @memberof LoggingService
     * @param {string|object} message
     * @description Write a trace level log entry
     */
    writeTrace(message: string | object) {
        this.write(LogLevel.Trace, message);
    }

    /**
     * @function writeDebug
     * @memberof LoggingService
     * @param {string|object} message
     * @description Write a debug level log entry
     */
    writeDebug(message: string | object) {
        this.write(LogLevel.Debug, message);
    }

    /**
     * @function writeInfo
     * @memberof LoggingService
     * @param {string|object} message
     * @description Write a info level log entry
     */
    writeInfo(message: string | object) {
        this.write(LogLevel.Info, message);
    }

    writeWarn(message: string | object) {
        this.write(LogLevel.Warn, message);
    }

    /**
     * @function writeError
     * @memberof LoggingService
     * @param {string|object} message
     * @description Write a error level log entry
     */
    writeError(message: string | object) {
        this.write(LogLevel.Error, message);
    }

    /**
     * @function writeFatal
     * @memberof LoggingService
     * @param {string|object} message
     * @description Write a fatal level log entry
     */
    writeFatal(message: string | object) {
        this.write(LogLevel.Fatal, message);
    }

    takeOverConsole() {
        const self = this;
        const console = window.console

        if (!console) {
            return
        }

        const intercept = (method) => {
            const original = console[method];
            console[method] = function () {
                // do sneaky stuff
                if (original.apply) {
                    if (method === "log" || method === "info") {
                        self.writeInfo(arguments);
                    } else if (method === "warn") {
                        self.writeWarn(arguments);
                    } else if (method === "error") {
                        self.writeError(arguments);
                    } else if (method === "trace") {
                        self.writeTrace(arguments);
                    }
                    // Do this for normal browsers
                    original.apply(console, arguments)
                }
                else {
                    // Do this for IE
                    const message = Array.prototype.slice.apply(arguments).join(' ')
                    original(message)
                }
            }
        }

        const methods = ['log', 'warn', 'error', 'error', 'info', 'trace']

        for (let i = 0; i < methods.length; i++) {
            intercept(methods[i])
        }
    }

    /**
     * listen for global runtime errors on the window object and log to backend
     */
    doSubscribeGlobalErrorEvent() {
        //const self = this;
        this._window.addEventListener("error", (error) => {
            this.writeError(error);
        });
    }

    doSubscribeUnhandledRejection() {
        this._window.addEventListener("unhandledrejection", (promiseRejectionEvent) => {
            console.warn("Handled promise rejection: " + promiseRejectionEvent.reason);
            this.writeWarn({ message: "Handled promise rejection", rejectionReason: promiseRejectionEvent.reason });
        });
    }

    doSubscribeHandledRejection() {
        this._window.addEventListener("rejectionhandled", (promiseRejectionEvent) => {
            console.warn("Unhandled promise rejection: " + promiseRejectionEvent.reason);
            this.writeWarn({ message: "Unhandled promise rejection", rejectionReason: promiseRejectionEvent.reason });
        });
    }

    /**
     * @function createUUID
     * @memberof LoggingService
     * @description Create a unique uuid
     */
    createUUID() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    /**
     * @function addBrowserUserTracking
     * @memberof LoggingService
     * @description Add some cookies to identify unique browser sessions on the backend
     */
    addBrowserUserTracking($cookies: any) {
        // get intuto analytics browser unique identifier value (if it exists
        let iaBid = $cookies.get("ia_bid");
        if (iaBid === null) {
            iaBid = this.createUUID();
            const now = (new Date());
            now.setDate(now.getDate() + 365);
            $cookies.put("ia_bid", iaBid, { domain: ".intuto.com", expires: now, samesite: "lax" });
        }
        // get intuto analytics browser session unique identifier value (if it exists
        let iaBsid = $cookies.get("ia_bsid");
        if (iaBsid === null) {
            iaBsid = this.createUUID();
            $cookies.put("ia_bsid", iaBsid, { domain: ".intuto.com", samesite: "lax" });
        }
    }
}

class GlobalLoggingServiceFactory {
    constructor(private window: Window, private intuto: any, private appSettings: any) { }

    create() {
        if (this.intuto.loggingService !== undefined) {
            return this.intuto.loggingService;
        }

        this.intuto.loggingService = new LoggingService(LogLevel.All, this.appSettings.intutoIdentityServer, window);

        if (this.appSettings.intutoIdentityServer.jsLoggingSubscribeGlobalError) {
            this.intuto.loggingService.doSubscribeGlobalErrorEvent();
        }

        return this.intuto.loggingService;
    }
}

const globalLoggingServiceFactory = new GlobalLoggingServiceFactory(window, window.intuto, window.intuto.appSettings);
globalLoggingServiceFactory.create();
