import { Component } from "@angular/core";
import { AppSettings } from "../../classes/app-settings";

@Component({
    selector: "site-notification",
    template: `
<div class="alert alert-warning site-notification">
    <div>{{ appSettings.siteNotification.message }}</div>
    <div class="mt-2" *ngIf="appSettings.siteNotification.link">
        <a [href]="appSettings.siteNotification.link" [target]="appSettings.siteNotification.openInNewWindow ? '_blank' : undefined">
            {{ appSettings.siteNotification.linkText || appSettings.siteNotification.link }}
        </a>
    </div>
</div>`,
    styleUrl: "./site-notification.scss"
})
export class SiteNotificationComponent {
    constructor(public appSettings: AppSettings) {}
}