import { Route, UrlSegment, UrlSegmentGroup } from "@angular/router";
export function caseInsensitiveMatcher(url: string) {
    return function (
        segments: UrlSegment[],
        _segmentGroup: UrlSegmentGroup,
        route: Route
    ) {
        const expectedUrlSegments = url.split("/");

        if (
            expectedUrlSegments.length > segments.length ||
            (expectedUrlSegments.length !== segments.length && route.pathMatch === "full")
        ) {
            return null;
        }

        const consumedSegments: UrlSegment[] = [];
        const extractedParams: { [paramName: string]: UrlSegment } = {};

        for (let index = 0; index < expectedUrlSegments.length; index += 1) {
            const actualSegment = segments[index].path;
            const expectedSegment = expectedUrlSegments[index];

            if (expectedSegment.startsWith(":")) {
                extractedParams[expectedSegment.slice(1)] = segments[index];
                consumedSegments.push(segments[index]);
            }
            else if (actualSegment.toLowerCase() === expectedSegment.toLowerCase()) {
                consumedSegments.push(segments[index]);
            }
            else {
                return null;
            }
        }

        return { consumed: consumedSegments, posParams: extractedParams };
    };
}
