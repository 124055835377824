import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { debounceTime } from "rxjs/operators";
import { EmailCheckResult } from "../classes/email-check-result";

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    checkEmail(email: string) {
        return this.http.get<EmailCheckResult>(`/api/user`, { params: { email: email } }).pipe(
            debounceTime(1000)
        );
    }
}