import { Injectable, ErrorHandler } from '@angular/core';
import { LoggingService } from "../services/logging.service";

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        this.loggingService.writeError(error);
        console.error(error);
    }
    constructor(
        private loggingService: LoggingService
    ) { }
}
