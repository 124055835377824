import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { AppSettings } from "../../../shared/classes/app-settings";
import { Tenant } from "../../../shared/classes/tenant";
import { HCaptchaComponent } from "../../../shared/components/hcaptcha/hcaptcha.component";
import { ValidationService } from "../../../shared/services/validation.service";
import { LoginResult } from "./classes/login-result";
import { LoginSettings } from "./classes/login-settings";
import { LoginState } from "./classes/login-state";
import { LoginStateType } from "./classes/login-state-type";
import * as fromLogin from "./store/login.actions";
import * as LoginSelectors from "./store/login.selectors";
import { LoggingService } from "../../../shared/services/logging.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, OnDestroy {
    destroy$ = new Subject<boolean>();
    form: FormGroup;
    settings$: Observable<LoginSettings>;
    state$: Observable<LoginState>;
    result$: Observable<LoginResult>;
    errorMessage$: Observable<string>;
    formIsReady: boolean;
    readonly LoginStateType: typeof LoginStateType = LoginStateType;
    @ViewChild("hCaptchaControl") hCaptchaComponent: HCaptchaComponent;
    @ViewChild("loginForm") loginFormElement: ElementRef<HTMLFormElement>;

    constructor(
        private window: Window,
        private formBuilder: FormBuilder,
        private validationService: ValidationService,
        private store: Store<LoginState>,
        public appSettings: AppSettings,
        public tenant: Tenant,
        private ngZone: NgZone,
        private loggingService: LoggingService
    ) {
        this.form = this.formBuilder.group({
            username: [null as string, {
                validators: [Validators.required, this.validationService.email()],
                updateOn: "change"
            }],
            password: [null as string, {validators: [], updateOn: "change"}],
            returnUrl: [null as string],
            captchaResponse: [null as string],
            antiforgeryToken: [null as string]
        });

        this.state$ = this.store.select(LoginSelectors.selectLoginState);

        this.store.select(LoginSelectors.selectLoginSettings).pipe(
            takeUntil(this.destroy$),
            filter(settings => !!settings),
            map(settings => {
                if (settings.redirectUrl) {
                    this.window.location.href = settings.redirectUrl;
                }

                this.form.controls.antiforgeryToken.setValue(settings.antiforgeryToken);
            })
        ).subscribe();

        this.result$ = this.store.select(LoginSelectors.selectLoginResult);

        this.result$.pipe(
            takeUntil(this.destroy$),
            filter(result => !!result),
            map(result => {
                if (result.redirectUrl) {
                    this.window.location.href = result.redirectUrl;
                }
            })
        ).subscribe();

        this.errorMessage$ = this.store.select(LoginSelectors.selectLoginErrorResult);

        this.store.select(LoginSelectors.selectQueryParams).pipe(
            takeUntil(this.destroy$),
            filter(queryParams => !!queryParams),
            map(queryParams => {
                if (queryParams.returnUrl) {
                    this.form.controls.returnUrl.setValue(queryParams.returnUrl);
                }
            })
        ).subscribe();

        this.store.select(LoginSelectors.selectFormReadyStatus).pipe(
            takeUntil(this.destroy$),
            map(formIsReady => this.formIsReady = formIsReady)
        ).subscribe();
    }

    ngOnInit() {
        this.store.dispatch(fromLogin.loadLoginState());
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    isFormReadyToSubmit(event: Event) {
        if (!this.formIsReady) {
            event.preventDefault();
        }

        return true;
    }

    onCaptchaVerified(event: Event) {
        this.loginWithFormValue();
    }

    onCaptchaError(event: Event) {
        console.log("HCaptcha Error");
        console.error(event);
    }

    onSubmit() {
        if (this.appSettings.captcha.captchaEnabled) {
            this.hCaptchaComponent.execute();
        }
        else {
            this.loginWithFormValue();
        }
    }

    private loginWithFormValue() {
        const formData = this.form.getRawValue();
        this.ngZone.run(() => this.store.dispatch(fromLogin.login({ request: formData })));
    }
}
