import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { caseInsensitiveMatcher } from "../../../shared/matchers/case-insensitive-matcher";
import { AppComponent } from "./app.component";
import { LoginEffects } from "./store/login.effects";
import { loginReducer } from "./store/login.reducer";

const routes: Routes = [
    { matcher: caseInsensitiveMatcher("account/login"), component: AppComponent },
    { path: "", redirectTo: "account/login", pathMatch: "full" }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        StoreModule.forRoot({ login: loginReducer }, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictActionWithinNgZone: true
            }
        }),
        EffectsModule.forRoot(LoginEffects)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }