import { Component } from "@angular/core";
import { AppSettings } from "../../classes/app-settings";
import { Tenant } from "../../classes/tenant";

@Component({
    selector: "intuto-logo",
    template: `
<div class="logo-wrapper">
    <img class="logo"
        [src]="'https://' + appSettings.intutoIdentityServer.siteUrl + '/company/' + tenant.id + '/logo'"
        alt="logo" />
</div>`,
    styleUrl: "./logo.scss"
})
export class LogoComponent {
    constructor(
        public appSettings: AppSettings,
        public tenant: Tenant
    ) { }
}
