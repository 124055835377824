import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ControlContainer, FormGroup, FormGroupDirective } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { LoginState } from "../../classes/login-state";
import { LoginStateType } from "../../classes/login-state-type";
import * as fromLogin from "../../store/login.actions";
import * as LoginSelectors from "../../store/login.selectors";

@Component({
    selector: "username-input",
    templateUrl: "./username-input.component.html",
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ]
})
export class UsernameInputComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("usernameInput") usernameInput: ElementRef;
    destroy$ = new Subject<boolean>();
    @Input() form: FormGroup;
    emailCheckInProgress: boolean;
    allowSiteRegister: boolean;
    showValidationErrors$: Observable<boolean>;

    constructor(
        private window: Window,
        private store: Store<LoginState>
    ) {
        this.showValidationErrors$ = this.store.select(LoginSelectors.selectErrorVisibility).pipe(
            map(errorVisibility => errorVisibility.username)
        );

        this.store.select(LoginSelectors.selectLoginSettings).pipe(
            takeUntil(this.destroy$),
            filter(settings => !!settings),
            map(settings => this.allowSiteRegister = settings.allowSiteRegister)
        ).subscribe();
    }

    ngOnInit() {
        this.store.select(LoginSelectors.selectLoginStateType).pipe(
            takeUntil(this.destroy$),
            map(stateType => {
                if (stateType === LoginStateType.EnterUsername) {
                    this.store.dispatch(fromLogin.unmarkFormAsReady());
                    this.store.dispatch(fromLogin.toggleErrorVisibility({ username: false }));
                    this.form.controls.password.clearValidators();
                    this.form.controls.password.updateValueAndValidity();
                }
            })
        ).subscribe();

        this.store.select(LoginSelectors.selectQueryParams).pipe(
            takeUntil(this.destroy$),
            filter(queryParams => !!queryParams),
            map(queryParams => {
                if (queryParams.username) {
                    this.form.controls.username.setValue(queryParams.username);
                }
            })
        ).subscribe();

        this.store.select(LoginSelectors.selectEmailCheckResult).pipe(
            takeUntil(this.destroy$),
            filter(result => !!result),
            map(result => {
                if (result.exists) {
                    if (result.hasLocalAuth) {
                        this.store.dispatch(fromLogin.setState({ stateType: LoginStateType.EnterPassword }));
                    }
                    else {
                        this.store.dispatch(fromLogin.setState({ stateType: LoginStateType.CreateLocalAuth }));
                    }
                }
                else {
                    if (this.allowSiteRegister) {
                        this.redirectToRegister();
                    }
                    else {
                        this.form.controls.username.setErrors({ notexist: true });
                    }
                }

                setTimeout(() => this.emailCheckInProgress = false);
            })
        ).subscribe();
    }

    ngAfterViewInit() {
        setTimeout(() => this.usernameInput.nativeElement.focus(), 500);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private redirectToRegister() {
        const registerUrl = new URL("/account/register", this.window.location.origin);
        registerUrl.searchParams.set("returnUrl", this.form.controls.returnUrl.value);
        registerUrl.searchParams.set("username", this.form.controls.username.value);

        this.window.location = registerUrl.href;
    }

    checkEmail() {
        if (this.emailCheckInProgress) {
            return;
        }

        this.emailCheckInProgress = true;

        this.form.controls.username.markAsTouched();
        this.store.dispatch(fromLogin.toggleErrorVisibility({ username: true }));

        if (this.form.valid) {
            this.store.dispatch(fromLogin.checkEmail({ email: this.form.controls.username.value }));
        }
        else {
            this.emailCheckInProgress = false;
        }
    }
}
