<div class="form-group">
    <p class="text-center">
        {{ form.controls.username.value }}
    </p>
</div>
<div class="form-group">
    <input
        formControlName="password"
        type="password"
        #passwordInput
        name="Password"
        class="form-control"
        autocomplete="current-password"
        placeholder="Enter your password" />
</div>
<div class="form-group">
    <button
        type="submit"
        class="form-control btn btn-primary"
        [disabled]="form.invalid || (formIsSubmitted$ | async)">
        <span>Log In</span>
        <i class="fas fa-arrow-right" [hidden]="formIsSubmitted$ | async"></i>
        <i class="fa fa-spinner fa-pulse fa-fw" [hidden]="!(formIsSubmitted$ | async)"></i>
    </button>
</div>
<div class="form-group">
    <a id="forgot-password-form-link"
       [href]="'/Account/Forgotpassword?returnUrl=' + form.controls.returnUrl.value + '&amp;email=' + form.controls.username.value">
        Having trouble logging in?
    </a>
</div>
<div class="form-group">
    <button type="button" class="btn btn-link" (click)="back()">Back</button>
</div>
