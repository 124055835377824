export class HcaptchaRenderConfig {
    sitekey: string;
    theme?: "light" | "dark" | "contrast" | object;
    size?: string;
    hl?: string;
    tplinks?: "on" | "off";
    tabindex?: number = undefined;
    custom?: boolean;
    callback?: string | ((response: string) => void);
    "open-callback"?: string | (() => void);
    "close-callback"?: string | (() => void);
    "chalexpired-callback"?: string | (() => void);
    "expired-callback"?: string | (() => void);
    "error-callback"?: string | ((error: HCaptchaErrorCallback) => void);
}
