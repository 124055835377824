/// <reference types="@hcaptcha/types" />
import {
    Component,
    ElementRef,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    Inject
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ControlContainer, FormGroup, FormGroupDirective } from "@angular/forms";
import { HcaptchaJsapiConfig } from "./hcaptcha-jsapi-config";
import { HcaptchaRenderConfig } from "./hcaptcha-render-config";
import { HcaptchaScriptLoader } from "./hcaptcha-script-loader";
import { HcaptchaVerifiedEvent } from "./hcaptcha-verified-event";
import { AppSettings } from "../../classes/app-settings";

declare global {
    interface Window {
        hcaptcha: HCaptcha;
    }
}

@Component({
    selector: "h-captcha",
    templateUrl: "./hcaptcha.component.html",
    viewProviders: [{
        provide: ControlContainer,
        useExisting: FormGroupDirective
    }]
})
export class HCaptchaComponent implements AfterViewInit {
    // Render Attributes
    @Input() autoRender: boolean = true;
    @Input() siteKey: string = null;
    @Input() size: string = null;
    @Input() theme: object = null;
    @Input() tabIndex: number = null;
    @Input() rqData: string = null;
    // API Attributes
    @Input() reCaptchaCompat: boolean;
    @Input() jsApi: string;
    @Input() endpoint: string = null;
    @Input() reportApi: string = null;
    @Input() assetHost: string = null;
    @Input() imgHost: string = null;
    @Input() sentry: boolean;
    @Input() hl: string = null;
    @Output() verified: EventEmitter<any> = new EventEmitter<any>();
    @Output() expired: EventEmitter<any> = new EventEmitter<any>();
    @Output() challengeExpired: EventEmitter<any> = new EventEmitter<any>();
    @Output() error: EventEmitter<any> = new EventEmitter<any>();
    @Output() opened: EventEmitter<any> = new EventEmitter<any>();
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Input() form: FormGroup;
    @ViewChild("captchaResponse") captchaResponseInput: ElementRef<HTMLInputElement>;
    @ViewChild("captchaContainer") captchaContainer: ElementRef<HTMLDivElement>;
    private hCaptchaJsApiConfig: HcaptchaJsapiConfig;
    private hCaptchaJsApiFallbackConfig: HcaptchaJsapiConfig;
    private hCaptchaRenderConfig: HcaptchaRenderConfig;
    private scriptLoader: HcaptchaScriptLoader;
    private hCaptchaId: HCaptchaId;
    private hCaptcha: HCaptcha;

    constructor(private appSettings: AppSettings,
                private window: Window,
                @Inject(DOCUMENT) document: Document) {
        this.scriptLoader = new HcaptchaScriptLoader(window, document);
    }

    ngAfterViewInit() {
        this.hCaptchaRenderConfig = this.getRenderConfig();
        this.hCaptchaJsApiConfig = this.getDefaultJsApiConfig();
        this.hCaptchaJsApiFallbackConfig = this.getFallbackJsApiConfig();
        // We don't use this however add anyway to prevent errors being logged
        this.window._hCaptchaOnLoad = (event) => {}
        this.scriptLoader.loadHCaptcha(this.hCaptchaJsApiConfig, this.hCaptchaJsApiFallbackConfig)
            .subscribe(() => {
                if (!this.autoRender) {
                    return;
                }

                this.hCaptcha = this.window.hcaptcha;
                this.hCaptchaId = this.render(this.captchaContainer.nativeElement, this.hCaptchaRenderConfig as ConfigRender);
                this.disableHCaptchaDefaultInputElements();

            }, (error) => {
                console.log("Error failed to load hCaptcha script");
                console.error(error);
            });
    }

    errorCallback(event: Event) {
        console.log('HCaptcha error');
        console.log(event);
    }

    execute(id?: HCaptchaId, config?: ConfigExecute): void | Promise<HCaptchaResponse> {
        if (!id) {
            id = this.hCaptchaId;
        }

        return this.hCaptcha.execute(id, config);
    }

    render(container: HTMLElement | string, config: ConfigRender): HCaptchaId {
        if (!this.window.hcaptcha) {
            throw new Error("HCaptcha api not loaded");
        }

        this.hCaptchaId = this.hCaptcha.render(this.captchaContainer.nativeElement, config);
        return this.hCaptchaId;
    }

    reset(id?: HCaptchaId): void {
        this.hCaptcha.reset(id);
    }

    close(id?: HCaptchaId): void {
        this.hCaptcha.close(id);
    }

    remove(id?: HCaptchaId): void {
        this.hCaptcha.remove(id);
    }

    getResponse(id?: HCaptchaId): string {
        return this.hCaptcha.getResponse(id);
    }

    getRespKey(id?: HCaptchaId): string {
        return this.hCaptcha.getRespKey(id);
    }

    setData(id: HCaptchaId, config: ConfigSetData): void {
        this.hCaptcha.setData(id, config);
    }

    private getRenderConfig() {
        const hCaptchaRenderConfig = new HcaptchaRenderConfig();
        hCaptchaRenderConfig.sitekey = this.siteKey ?? this.appSettings.captcha.captchaPublicKey;
        hCaptchaRenderConfig.size = this.size ?? hCaptchaRenderConfig.size;
        hCaptchaRenderConfig.theme = this.theme ?? hCaptchaRenderConfig.theme;
        hCaptchaRenderConfig.tabindex = this.tabIndex ?? hCaptchaRenderConfig.tabindex;
        hCaptchaRenderConfig.hl = this.hl ?? hCaptchaRenderConfig.hl;
        hCaptchaRenderConfig.callback = () => {
            const verifiedEvent = new HcaptchaVerifiedEvent();
            verifiedEvent.token = this.hCaptcha.getResponse(this.hCaptchaId);
            verifiedEvent.eKey = this.hCaptcha.getRespKey(this.hCaptchaId);
            this.form.controls.captchaResponse.setValue(verifiedEvent.token);
            this.verified.emit(verifiedEvent);
        };

        hCaptchaRenderConfig["expired-callback"] = () => {
            this.expired.emit();
        };

        hCaptchaRenderConfig["chalexpired-callback"] = () => {
            this.challengeExpired.emit();
        }
        hCaptchaRenderConfig["error-callback"] = () => {
            this.error.emit();
        };

        hCaptchaRenderConfig["open-callback"] = () => {
            this.opened.emit();
        };

        hCaptchaRenderConfig["close-callback"] = () => {
            this.closed.emit();
        };

        return hCaptchaRenderConfig;
    }

    private getDefaultJsApiConfig(): HcaptchaJsapiConfig {
        const hCaptchaJsApiConfig = new HcaptchaJsapiConfig();
        hCaptchaJsApiConfig.jsapi = this.jsApi ?? hCaptchaJsApiConfig.jsapi;
        hCaptchaJsApiConfig.endpoint = this.endpoint ?? hCaptchaJsApiConfig.endpoint;
        hCaptchaJsApiConfig.reportapi = this.reportApi ?? hCaptchaJsApiConfig.reportapi;
        hCaptchaJsApiConfig.assethost = this.assetHost ?? hCaptchaJsApiConfig.assethost;
        hCaptchaJsApiConfig.imghost = this.imgHost ?? hCaptchaJsApiConfig.imghost;
        return hCaptchaJsApiConfig;
    }

    private getFallbackJsApiConfig(): HcaptchaJsapiConfig {
        const hCaptchaJsApiConfig = new HcaptchaJsapiConfig();
        hCaptchaJsApiConfig.jsapi = "https://cn1.hcaptcha.com/1/api.js";
        hCaptchaJsApiConfig.endpoint = "https://cn1.hcaptcha.com";
        hCaptchaJsApiConfig.reportapi = "https://reportapi-cn1.hcaptcha.com";
        hCaptchaJsApiConfig.assethost = "https://assets-cn1.hcaptcha.com";
        hCaptchaJsApiConfig.imghost = "https://imgs-cn1.hcaptcha.com";
        return hCaptchaJsApiConfig;
    }

    /**
     * Prevent built in hcaptcha form textarea input(s) from posting we use our own hidden input element
     */
    private disableHCaptchaDefaultInputElements() {
        const hCaptchaResponseInput = this.captchaContainer.nativeElement.querySelector("[name='h-captcha-response']") as HTMLTextAreaElement;
        const gReCaptchaResponseInput = this.captchaContainer.nativeElement.querySelector("[name='g-recaptcha-response']") as HTMLTextAreaElement;

        if (hCaptchaResponseInput) {
            hCaptchaResponseInput.disabled = true;
        }
        if (gReCaptchaResponseInput) {
            gReCaptchaResponseInput.disabled = true;
        }
    }
}
