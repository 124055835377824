import { createAction, props } from '@ngrx/store';
import { EmailCheckResult } from '../../../../shared/classes/email-check-result';
import { QueryParams } from '../../../../shared/classes/query-params';
import { ErrorVisibility } from '../classes/error-visibility';
import { LoginSettings } from '../classes/login-settings';
import { LoginState } from '../classes/login-state';
import { LoginStateType } from '../classes/login-state-type';
import { LoginResult } from '../classes/login-result';
import { LoginRequest } from "../classes/login-request";

export const setState = createAction(
    '[Login] Set State',
    props<{ stateType: LoginStateType }>()
);

export const stateTypeSet = createAction(
    '[Login] State Type Set',
    props<{ stateType: LoginStateType }>()
);

export const loadLoginState = createAction(
    '[Login] Load Login State'
);

export const loginStateLoaded = createAction(
    '[Login] Login State Loaded',
    props<{ state: LoginState }>()
);

export const loadLoginSettings = createAction(
    '[Login] Load Login Settings'
);

export const loginSettingsLoaded = createAction(
    '[Login] Login Settings Loaded',
    props<{ settings: LoginSettings }>()
);

export const checkEmail = createAction(
    '[Login] Check Email',
    props<{ email: string }>()
);

export const emailChecked = createAction(
    '[Login] Email Checked',
    props<{ emailCheckResult: EmailCheckResult }>()
);

export const queryParamsLoaded = createAction(
    '[Login] Query Params Loaded',
    props<{ queryParams: QueryParams }>()
);

export const loginResultLoaded = createAction(
    '[Login] Login Result Loaded',
    props<{ result: LoginResult }>()
);

export const toggleErrorVisibility = createAction(
    '[Login] Toggle Error Visibility',
    props<{ username?: boolean, password?: boolean }>()
);

export const errorVisibilityChanged = createAction(
    '[Login] Error Visibility Changed',
    props<{ errorVisibility: ErrorVisibility }>()
);

export const markFormAsReady = createAction(
    '[Login] Mark Form As Ready'
);

export const unmarkFormAsReady = createAction(
    '[Login] Unmark Form As Ready'
);

export const formReadyStatusChanged = createAction(
    '[Login] Form Readiness Changed',
    props<{ formIsReady: boolean }>()
);

export const formSubmittedStatusChanged = createAction(
    '[Login] Form Submitted Status Changed',
    props<{ formIsSubmitted: boolean }>()
);

export const login = createAction(
    '[Login] Login',
    props<{ request: LoginRequest }>()
);

export const loginSuccess = createAction(
    '[Login] Login Success',
    props<{ result: LoginResult }>()
);

export const loginFailure = createAction(
    '[Login] Login Failure',
    props<{ error: string }>()
);
