export class HcaptchaJsapiConfig {

    /**
     * The hCaptcha JS API.
     * Default: "https://js.hcaptcha.com/1/api.js"
     */
    jsapi: string = "https://hcaptcha.com/1/api.js";
    render: string = "explicit"
    onload: string = "_hCaptchaOnLoad"
    /**
     * Default: true
     */
    sentry?: string = "true";
    /**
     * Disable drop-in replacement for reCAPTCHA with false to prevent
     * hCaptcha from injecting into window.grecaptcha.
     * Default: true
     */
    recaptchacompat?: string;
    /**
     * hCaptcha auto-detects language via the user's browser.
     * This overrides that to set a default UI language.
     */
    hl?: string;
    endpoint?: string;
    reportapi?: string;
    assethost?: string;
    imghost?: string;
    host?: string;
}
