export class AppSettings {
    intutoIdentityServer: {
        siteUrl: string,
        dateMin: string,
        dateMax: string,
        passwordMinLength: number,
        profileFieldMaxLength: number,
        angularJSDebugEnabled: boolean,
        javaScriptLoggingExcludeStringList: string,
        javaScriptLoggingEnabled: boolean,
        javaScriptLoggingSubscribeGlobalError: boolean,
        javaScriptLoggingSubscribeAngularJsError: boolean,
        javaScriptWebOptimizerMinificationEnabled: boolean,
        javaScriptLoggingLogUnhandledPromiseRejection: boolean,
        javaScriptLoggingLogHandledPromiseRejection: boolean
    };

    captcha: {
        captchaEnabled: boolean,
        captchaPublicKey: string
    };

    siteNotification: {
        enabled: boolean,
        message: string,
        link: string,
        linkText: string,
        openInNewWindow: boolean
    };
}