import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroup, FormGroupDirective } from "@angular/forms";
import { Store } from "@ngrx/store";
import { LoginState } from "../../classes/login-state";
import { LoginStateType } from "../../classes/login-state-type";
import * as fromLogin from "../../store/login.actions";

@Component({
    selector: "local-auth-creation",
    templateUrl: "./local-auth-creation.component.html",
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ]
})
export class LocalAuthCreationComponent {
    @Input() form: FormGroup;

    constructor(private store: Store<LoginState>) {
    }

    back() {
        this.store.dispatch(fromLogin.setState({ stateType: LoginStateType.EnterUsername }));
    }
}