<div class="form-group">
    <p class="text-center">
        {{ form.controls.username.value }}
    </p>
</div>
<div class="form-group">
    <a
        id="local-auth-link"
        class="form-control btn btn-primary"
        [href]="'/Account/SetPassword?username=' + form.controls.username.value + '&amp;returnUrl=' + form.controls.returnUrl.value">
        <span>Create a Password</span>
        <i class="fas fa-arrow-right"></i>
    </a>
</div>
<div class="form-group">
    <button type="button" class="btn btn-link" (click)="back()">Back</button>
</div>
