/// <reference types="@hcaptcha/types" />
import { Observable, Subscriber } from 'rxjs';
import { HcaptchaJsapiConfig } from "./hcaptcha-jsapi-config";

declare global {
    interface Window {
        _hCaptchaOnLoad: any;
        hcaptcha: HCaptcha;
    }
}

export class HcaptchaScriptLoader {
    constructor(private window: Window, private document: Document) { }

    loadHCaptcha(hCaptchaJsApiConfig: HcaptchaJsapiConfig, hCaptchaJsApiBackupConfig: HcaptchaJsapiConfig = null) {
        return new Observable<void>((observer: Subscriber<void>) => {
            // No window object (ssr)
            if (!this.window) {
                return;
            }

            // The hCaptcha script has already been loaded
            if (typeof this.window.hcaptcha !== 'undefined') {
                observer.next();
                observer.complete();
                return;
            }

            const onSuccess = () => {
                observer.next();
                observer.complete();
            }

            const onSecondFailure = (error) => {
                observer.error(error);
            }

            const onFirstFailure = () => {
                // Create a new script with our alternate config
                this.appendScriptElement(hCaptchaJsApiBackupConfig,
                    onSuccess,
                    onSecondFailure
                );
            }

            this.appendScriptElement(hCaptchaJsApiConfig,
                onSuccess,
                onFirstFailure
            );
        });
    }

    private appendScriptElement(hCaptchaJsApiConfig: HcaptchaJsapiConfig, onSuccess: () => void, onFailure: (error: any) => void) {
        const script = this.document.createElement('script') as HTMLScriptElement;
        script.src = this.generateScriptSrc(hCaptchaJsApiConfig);
        script.async = true;
        script.defer = true;
        script.onload = onSuccess;
        script.onerror = onFailure;
        this.document.head.appendChild(script);
    }

    private generateScriptSrc(hCaptchaJsApiConfig: HcaptchaJsapiConfig): string {
        const queryParams = new URLSearchParams();
        this.tryAddQueryStringItem(queryParams, 'render', hCaptchaJsApiConfig.render);
        this.tryAddQueryStringItem(queryParams, 'onload', hCaptchaJsApiConfig.onload);
        this.tryAddQueryStringItem(queryParams, 'recaptchacompat', hCaptchaJsApiConfig.recaptchacompat === 'false' ? 'off' : undefined);
        this.tryAddQueryStringItem(queryParams, 'host', hCaptchaJsApiConfig.host);
        this.tryAddQueryStringItem(queryParams, 'hl', hCaptchaJsApiConfig.hl);
        this.tryAddQueryStringItem(queryParams, 'sentry', hCaptchaJsApiConfig.sentry === 'false' ? 'false' : 'true');
        this.tryAddQueryStringItem(queryParams, 'endpoint', hCaptchaJsApiConfig.endpoint);
        this.tryAddQueryStringItem(queryParams, 'assethost', hCaptchaJsApiConfig.assethost);
        this.tryAddQueryStringItem(queryParams, 'imghost', hCaptchaJsApiConfig.imghost);
        this.tryAddQueryStringItem(queryParams, 'reportapi', hCaptchaJsApiConfig.reportapi);
        return hCaptchaJsApiConfig.jsapi + "?" + queryParams.toString();
    }

    private tryAddQueryStringItem(urlSearchParams: URLSearchParams, key: string, value: string): void {
        if (value !== undefined && value !== null) {
            urlSearchParams.append(key, value);
        }
    }
}
