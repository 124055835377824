import { createReducer, on } from '@ngrx/store';
import { LoginState } from '../classes/login-state';
import { LoginStateType } from '../classes/login-state-type';
import * as fromLogin from '../store/login.actions';

const initialState: LoginState = {
    stateType: LoginStateType.EnterUsername,
    errorVisibility: { username: false, password: false },
    formIsReady: false,
    formIsSubmitted: false
};

export const loginReducer = createReducer(
    initialState,
    on(fromLogin.queryParamsLoaded, (state, { queryParams }) => ({
        ...state,
        queryParams
    })),
    on(fromLogin.loginSettingsLoaded, (state, { settings }) => ({
        ...state,
        settings
    })),
    on(fromLogin.stateTypeSet, (state, { stateType }) => ({
        ...state,
        stateType
    })),
    on(fromLogin.emailChecked, (state, { emailCheckResult }) => ({
        ...state,
        emailCheckResult
    })),
    on(fromLogin.formReadyStatusChanged, (state, { formIsReady }) => ({
        ...state,
        formIsReady
    })),
    on(fromLogin.errorVisibilityChanged, (state, { errorVisibility }) => ({
        ...state,
        errorVisibility
    })),
    on(fromLogin.formSubmittedStatusChanged, (state, { formIsSubmitted }) => ({
        ...state,
        formIsSubmitted
    })),
    on(fromLogin.loginSuccess, (state, { result }) => ({
        ...state,
        result
    })),
    on(fromLogin.loginFailure, (state, { error }) => ({
        ...state,
        error
    }))
);
