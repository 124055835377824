import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { LoginRequest } from "../classes/login-request";
import { LoginResult } from "../classes/login-result";
import { LoginSettings } from "../classes/login-settings";
import { Observable } from "rxjs";
import { QueryParams } from "../../../../shared/classes/query-params";

@Injectable()
export class LoginService {
    constructor(private http: HttpClient) { }

    loadSettings(queryParams: QueryParams) {
        return this.http.get<LoginSettings>(`/api/login/`, { params: { returnUrl: queryParams.returnUrl } }).pipe(
            map(response => new LoginSettings(response))
        );
    }

    login(loginRequest: LoginRequest): Observable<LoginResult> {
        return this.http.post<LoginResult>(`/api/login/`, loginRequest).pipe(
            map(response => new LoginResult(response))
        );
    }
}
