import { HttpClientModule } from "@angular/common/http";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppSettings } from "../../../shared/classes/app-settings";
import { Tenant } from "../../../shared/classes/tenant";
import { HCaptchaComponent } from "../../../shared/components/hcaptcha/hcaptcha.component";
import { SharedComponentModule } from "../../../shared/components/shared-component.module";
import { SiteNotificationComponent } from "../../../shared/components/site-notification/site-notification.component";
import { LoggingService} from "../../../shared/services/logging.service";
import { UserService } from "../../../shared/services/user.service";
import { ValidationService } from "../../../shared/services/validation.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ExternalProvidersComponent } from "./components/external-providers/external-providers.component";
import { LocalAuthCreationComponent } from "./components/local-auth-creation/local-auth-creation.component";
import { PasswordInputComponent } from "./components/password-input/password-input.component";
import { UsernameInputComponent } from "./components/username-input/username-input.component";
import { LoginService } from "./services/login.service";
import { CustomErrorHandler } from "../../../shared/providers/customerrorhandler.provider";
import { loggingProvider } from "../../../shared/providers/logging-provider";

declare let intuto: any;

@NgModule({
    declarations: [
        AppComponent,
        SiteNotificationComponent,
        ExternalProvidersComponent,
        UsernameInputComponent,
        PasswordInputComponent,
        LocalAuthCreationComponent,
        HCaptchaComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        SharedComponentModule
    ],
    exports: [],
    providers: [
        {
            provide: AppSettings,
            useValue: intuto.appSettings
        },
        {
            provide: Tenant,
            useValue: intuto.tenant
        },
        {
            provide: Window,
            useValue: window
        },
        loggingProvider,
	    {
            provide: ErrorHandler,
            useClass: CustomErrorHandler
        },
        LoginService,
        UserService,
        ValidationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
