export class HttpValidationProblemDetails {
    type: string | null;
    title: string | null;
    status: number | null;
    errors: { [key: string]: string[] } | null;

    constructor(json: any = null) {
        if (json !== null) {
            Object.assign(this, json);
        }
    }

    /**
     * Get the first error in the dictionary of errors
     */
    firstError() {
        if (this.errors === null) {
            return null;
        }

        const propertyNames = Object.getOwnPropertyNames(this.errors);
        if (propertyNames.length === 0) {
            return null;
        }

        const firstPropertyName = propertyNames[0]
        const firstEntry = this.errors[firstPropertyName];
        return firstEntry[0]
    }
}
