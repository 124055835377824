import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { LoginState } from "../../classes/login-state";
import { LoginStateType } from "../../classes/login-state-type";
import * as LoginSelectors from "../../store/login.selectors";

@Component({
    selector: "external-providers",
    templateUrl: "./external-providers.component.html"
})
export class ExternalProvidersComponent {
    @Input() form: FormGroup;
    state$: Observable<LoginState>;

    readonly LoginStateType: typeof LoginStateType = LoginStateType;

    constructor(private store: Store<LoginState>) {
        this.state$ = this.store.select(LoginSelectors.selectLoginState);
    }
}